import moment from 'moment-timezone';

import { FormValue } from '../components/Form';
import {
  ASCENDING_ORDER_DIRECTION,
  DESCENDING_ORDER_DIRECTION,
  FORBIDDEN_PATH,
  GMT_TIMEZONE,
  LOGIN_PATH,
  OrderDirection,
  PageFilterConfig,
  PageSearchQueryConfig,
  TableListData,
} from './constants';

export const getTableListDefaultState = <T>(): TableListData<T> => ({
  data: [] as T[],
  pagination: {
    page: 1,
    limit: 10,
    totalCount: 0,
    totalPages: 1,
  },
  sortOrder: {
    order: DESCENDING_ORDER_DIRECTION as OrderDirection,
    sortBy: '',
  },
  search: '',
  filter: {} as PageFilterConfig,
});

export const compareObjects = (key: string, order = ASCENDING_ORDER_DIRECTION) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (firstObject: any, secondObject: any): number => {
    if (!firstObject.hasOwnProperty(key) || !secondObject.hasOwnProperty(key)) {
      return 0;
    }

    const firstValue =
      typeof firstObject[key] === 'string'
        ? firstObject[key].toUpperCase()
        : firstObject[key] && firstObject[key].label
        ? firstObject[key].label
        : firstObject[key];

    const secondValue =
      typeof secondObject[key] === 'string'
        ? secondObject[key].toUpperCase()
        : secondObject[key] && secondObject[key].label
        ? secondObject[key].label
        : secondObject[key];

    let comparison = 0;
    if (firstValue > secondValue) {
      comparison = 1;
    } else if (firstValue < secondValue) {
      comparison = -1;
    }

    return order === DESCENDING_ORDER_DIRECTION ? comparison * -1 : comparison;
  };
};

export const redirectUserToSSOUrl = (): void => {
  const redirectUrl = process.env.REACT_APP_MBA_RANKINGS_ADMIN_REDIRECT_URL;
  const isCurrentRouteOnForbidden = window.location.pathname === FORBIDDEN_PATH;
  if (typeof window !== 'undefined' && redirectUrl && !isCurrentRouteOnForbidden) {
    window.location.href = redirectUrl;
  }
};

export const redirectUserToForbiddenUrl = (): void => {
  const isSameCurrentRoute = window.location.pathname === FORBIDDEN_PATH;
  if (typeof window !== 'undefined' && !isSameCurrentRoute) {
    window.location.href = FORBIDDEN_PATH;
  }
};

export const redirectUserToLogin = (): void => {
  const isAuthRoute = window.location.pathname.startsWith('/auth');
  if (typeof window !== 'undefined' && !isAuthRoute) {
    window.location.href = LOGIN_PATH;
  }
};

export const goToPublicPortal = (url: string): void => {
  const publicPortalUrl = process.env.REACT_APP_MBA_PUBLIC_PORTAL;
  if (typeof window !== 'undefined') {
    window.open(`${publicPortalUrl}/${url}`);
  }
};

export const generateSearchQuery = (pageConfig: PageSearchQueryConfig): string => {
  const { filter, limit, order, page, sortBy, search } = pageConfig;
  const queryParams = [];

  if (page) {
    queryParams.push(`page=${page}`);
  }

  if (limit) {
    queryParams.push(`limit=${limit}`);
  }

  if (sortBy) {
    queryParams.push(`sortBy=${sortBy}`);
  }

  if (order) {
    queryParams.push(`order=${order}`);
  }

  if (search) {
    queryParams.push(`search=${search.trim()}`);
  }

  if (filter) {
    queryParams.push(
      Object.entries(filter).reduce(
        (filterQuery, [key, value]): string =>
          value.reduce(
            (currentFilterQuery, filterValue): string =>
              `filter[${key}]=${filterValue}${currentFilterQuery ? `&${currentFilterQuery}` : ''}`,
            '',
          ) + `${filterQuery ? `&${filterQuery}` : ''}`,
        '',
      ),
    );
  }

  return queryParams.length ? `?${queryParams.join('&')}` : '';
};

export const capitalizeText = (text: string): string => {
  const textString = text ? text.toString() : '';
  return textString.charAt(0).toUpperCase() + textString.slice(1);
};

export const mapOrigamiComponentsToQuery = (components: string[]): string =>
  components.reduce((componentsList, origamiComponentString: string) => {
    return componentsList ? `${componentsList},${origamiComponentString}` : origamiComponentString;
  }, '');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getPropertyValue = <T extends Record<string, any>, K extends keyof T>(
  objectData: T,
  key: K,
): FormValue => {
  let value: FormValue = '';

  if (objectData.hasOwnProperty(key)) {
    let currentProperty: FormValue = objectData[key] ?? '';
    if (
      currentProperty !== null &&
      typeof currentProperty === 'object' &&
      !Array.isArray(currentProperty) &&
      'key' in currentProperty
    ) {
      currentProperty = currentProperty['key'];
    }

    value = currentProperty;
  }

  return value;
};

export const getFormErrors = <T extends Record<string, string[]>, K extends keyof T>(
  objectData: T,
  key: K,
): string[] => {
  let errors: string[] = [];

  if (objectData.hasOwnProperty(key)) {
    errors = objectData[key];
  }

  return errors;
};

export const formatDateString = (date?: string, dateFormat = 'DD/MM/YYYY', isTimeZoneShown?: boolean): string => {
  let formattedPublicationDate = '';

  if (date) {
    formattedPublicationDate = moment.tz(date, GMT_TIMEZONE).format(dateFormat) + (isTimeZoneShown ? ' GMT' : '');
  }

  return formattedPublicationDate;
};

export const getParsedDate = (date: string | number): Date => {
  const transformedDate = moment(date, 'DD/MM/YYYY').toString();
  return transformedDate === 'Invalid date' ? new Date(date) : new Date(transformedDate);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const jsonParse = (jsonString: string): Record<string, any> => {
  try {
    return jsonString ? JSON.parse(jsonString) : {};
  } catch (e: any) {
    console.log(e);
    return {};
  }
};

/* eslint-disable @typescript-eslint/no-explicit-any */
const compareValue = <T extends Record<string | number, any>>(
  value1: T,
  value2: T,
  deep = 0,
  compareKeys?: (string | number)[],
): boolean => {
  if (value1 === value2) {
    return true;
  } else if (!value1 || !value2) {
    return !value1 === !value2;
  } else if (typeof value1 !== typeof value2) {
    return false;
  }

  const keys: string[] = Array.from(new Set([...Object.keys(value1), ...Object.keys(value2)])).sort();
  let res = true;
  if (typeof value1 === 'object' && !Array.isArray(value1)) {
    keys.forEach((key: number | string): any => {
      if (deep > 0 || compareKeys?.includes(key)) {
        res = res && compareValue(value1[key], value2[key], deep + 1);
      }
    });
  } else if (Array.isArray(value1) && Array.isArray(value2) && value1.length === value2.length) {
    const v1: any[] = [...value1].sort();
    const v2: any[] = [...value2].sort();
    v1.forEach((key: any, index: number): any => {
      res = res && compareValue(v1[index], v2[index], deep + 1);
    });
  } else {
    return value1 === value2;
  }

  return res;
};

export const compareValues = <T extends Record<string | number, any>>(
  value1: T,
  value2: T,
  compareKeys?: (string | number)[],
): boolean => {
  return compareValue(value1, value2, 0, compareKeys);
};
/* eslint-enable @typescript-eslint/no-explicit-any */

export const validateFilename = (filename: string): string => {
  return (
    filename
      .trim()
      // eslint-disable-next-line no-useless-escape
      .replace(/["'#<>$+%!`&{}?=\\/:@\[\]]/gi, '')
      .replace(/ /gi, '-')
  );
};

export const getRenamedFile = (file: File, newFileName: string): File => {
  return new File([file], newFileName, {
    type: file.type,
  });
};
