import { call, CallEffect, select, put, PutEffect, SelectEffect, takeLatest } from 'redux-saga/effects';

import {
  BasicResponseDTO,
  DefaultResponseDTO,
  PagedResponseDTO,
  PageSearchQueryConfig,
} from '../../../shared/constants';
import {
  GlobalRequestActions,
  setRequestFailedAction,
  setRequestStartedAction,
  setRequestSucceededAction,
} from '../../../shared/state/global-request';
import { loadSchoolWidgetGroupsAction, setSchoolWidgetGroupsAction } from './school-widget-groups.actions';
import {
  createSchoolWidgetGroupRequest,
  deleteSchoolWidgetGroupRequest,
  getSchoolWidgetGroupsRequest,
  updateSchoolWidgetGroupRequest,
} from './school-widget-groups.api';
import { SchoolWidgetGroupDto } from './school-widget-groups.dto';
import { selectWidgetGroupsPageConfiguration } from './school-widget-groups.selectors';
import {
  CREATE_SCHOOL_WIDGET_GROUP,
  CreateSchoolWidgetGroupAction,
  DELETE_SCHOOL_WIDGET_GROUP,
  LOAD_SCHOOL_WIDGET_GROUPS,
  LoadSchoolWidgetGroupsAction,
  SchoolWidgetGroupsActionTypes,
  UPDATE_SCHOOL_WIDGET_GROUP,
  UpdateSchoolWidgetGroupAction,
} from './school-widget-groups.types';
import { getParsedDate } from '../../../shared/helpers';

function* createSchoolWidgetGroupSaga(
  action: CreateSchoolWidgetGroupAction,
): Generator<
  PutEffect<GlobalRequestActions | SchoolWidgetGroupsActionTypes> | CallEffect<BasicResponseDTO> | SelectEffect
> {
  const {
    type: actionType,
    payload: { savingData },
  } = action;

  try {
    yield put(setRequestStartedAction(actionType));

    const activeFrom = getParsedDate(savingData.activeFromDate);
    const utcActiveFrom = Date.UTC(activeFrom.getFullYear(), activeFrom.getMonth(), activeFrom.getDate());
    const activeTo = getParsedDate(savingData.activeToDate);
    const utcActiveTo = Date.UTC(activeTo.getFullYear(), activeTo.getMonth(), activeTo.getDate());

    const { data } = (yield call(createSchoolWidgetGroupRequest, {
      ...savingData,
      activeFromDate: utcActiveFrom,
      activeToDate: utcActiveTo,
    })) as DefaultResponseDTO<SchoolWidgetGroupDto>;
    if (data) {
      const pageConfig = (yield select(selectWidgetGroupsPageConfiguration)) as PageSearchQueryConfig;
      yield put(loadSchoolWidgetGroupsAction(pageConfig));
      yield put(setRequestSucceededAction(actionType));
    }
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* loadSchoolWidgetGroupsTableData(
  action: LoadSchoolWidgetGroupsAction,
): Generator<
  | CallEffect<PagedResponseDTO<SchoolWidgetGroupDto[]>>
  | PutEffect<SchoolWidgetGroupsActionTypes>
  | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { pageConfig } = action.payload;
    const { data, pagination, sortOrder } = (yield call(getSchoolWidgetGroupsRequest, pageConfig)) as PagedResponseDTO<
      SchoolWidgetGroupDto[]
    >;
    yield put(setSchoolWidgetGroupsAction({ data, pagination, sortOrder }));
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* updateSchoolWidgetGroupTableData(
  action: UpdateSchoolWidgetGroupAction,
): Generator<
  CallEffect<DefaultResponseDTO<undefined>> | PutEffect<SchoolWidgetGroupsActionTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { id, updateWidgetGroupDto } = action.payload;
    yield call(updateSchoolWidgetGroupRequest, id, updateWidgetGroupDto);
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

function* deleteSchoolWidgetGroup(
  action: UpdateSchoolWidgetGroupAction,
): Generator<
  CallEffect<DefaultResponseDTO<undefined>> | PutEffect<SchoolWidgetGroupsActionTypes> | PutEffect<GlobalRequestActions>
> {
  const { type: actionType } = action;

  try {
    yield put(setRequestStartedAction(actionType));
    const { id } = action.payload;
    yield call(deleteSchoolWidgetGroupRequest, id);
    yield put(setRequestSucceededAction(actionType));
  } catch (error: any) {
    yield put(setRequestFailedAction(actionType, error));
  }
}

export default function* schoolWidgetGroupsSaga(): Generator {
  yield takeLatest(CREATE_SCHOOL_WIDGET_GROUP, createSchoolWidgetGroupSaga);
  yield takeLatest(LOAD_SCHOOL_WIDGET_GROUPS, loadSchoolWidgetGroupsTableData);
  yield takeLatest(UPDATE_SCHOOL_WIDGET_GROUP, updateSchoolWidgetGroupTableData);
  yield takeLatest(DELETE_SCHOOL_WIDGET_GROUP, deleteSchoolWidgetGroup);
}
