import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useParams, useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import Button, { ButtonIcons, ButtonSizes } from '../../../../components/Button';
import { IconArrowDown, IconArrowUp, IconDelete, IconEdit } from '../../../../components/CustomIcons';
import EditableGroup from '../../../../components/EditableGroup';
import { FormConfig, FormDataValues } from '../../../../components/Form';
import Label, { LabelStatus } from '../../../../components/Label';
import LoadingSpinner from '../../../../components/LoadingSpinner';
import Modal from '../../../../components/Modal';
import { SelectOptionType } from '../../../../components/Select';
import TabsNavigation from '../../../../components/TabsNavigation';
import Typography, { TypographyVariants } from '../../../../components/Typography';
import { Resource } from '../../../../shared/constants';
import {
  clearRequestFailedAction,
  selectRequestErrorMessage,
  selectRequestIsLoading,
  selectRequestStatus,
} from '../../../../shared/state/global-request';
import {
  loadRankingTypesDropdownAction,
  selectRankingTypesListForDropdown,
} from '../../../../shared/state/ranking-types-dropdown';
import { loadSchoolsDropdownAction, selectSchoolListForDropdown } from '../../../../shared/state/school-resources';
import {
  DELETE_SCHOOL_WIDGET_GROUP,
  deleteSchoolWidgetGroupAction,
  UPDATE_SCHOOL_WIDGET_GROUP,
  updateSchoolWidgetGroupAction,
} from '../../SchoolWidgetGroups';
import {
  CreateSchoolWidgetGroupStatus,
  STATUS_LIST_BY_KEY,
  UpdateWidgetGroupDto,
} from '../../SchoolWidgetGroups/components/CreateWidgetGroupModal/CreateWidgetGroupModal.constants';
import {
  createSchoolWidgetAction,
  deleteSchoolWidgetAction,
  loadSchoolWidgetGroupAction,
  loadSchoolWidgetsAction,
  updateSchoolWidgetAction,
  updateSchoolWidgetOrderAction,
} from '../school-widget-details.actions';
import { selectSchoolWidgetGroup, selectSchoolWidgetsList } from '../school-widget-details.selectors';
import { CREATE_SCHOOL_WIDGET, LOAD_SCHOOL_WIDGETS, UPDATE_SCHOOL_WIDGET_ORDER } from '../school-widget-details.types';
import CreateWidgetModal from './CreateWidgetModal';
import EditableWidget from './EditableWidget';
import { NewWidgetDTO, WidgetDTO, WidgetOrderDTO } from './EditableWidget/EditableWidget.constants';
import RemoveWidgetPopup from './RemoveWidgetPopup';
import RemoveWidgetGroupPopup from './RemoveWidgetGroupPopup';
import {
  CreatableWidget,
  schoolWidgetConfig,
  SCHOOL_WIDGETS_TABS_NAVIGATION,
  SchoolWidgetDetailsDTO,
} from './SchoolWidgetDetails.constants';

import styles from './SchoolWidgetDetails.module.scss';
import NotFound from '../../../../components/NotFound';
import { getParsedDate } from '../../../../shared/helpers';

const SchoolWidgetDetails: FunctionComponent = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();

  const widgets = useSelector(selectSchoolWidgetsList);
  const rawWidgetGroup = useSelector(selectSchoolWidgetGroup);
  const schools = useSelector(selectSchoolListForDropdown);
  const rankingTypes = useSelector(selectRankingTypesListForDropdown);

  const updateStatus = useSelector(selectRequestStatus(UPDATE_SCHOOL_WIDGET_GROUP));

  const isCreateLoading = useSelector(selectRequestIsLoading(CREATE_SCHOOL_WIDGET));
  const isLoading = useSelector(selectRequestIsLoading(LOAD_SCHOOL_WIDGETS));
  const isUpdateWidgetLoading = useSelector(selectRequestIsLoading(UPDATE_SCHOOL_WIDGET_GROUP));
  const isUpdateWidgetOrderLoading = useSelector(selectRequestIsLoading(UPDATE_SCHOOL_WIDGET_ORDER));
  const orderRequestError = useSelector(selectRequestErrorMessage(UPDATE_SCHOOL_WIDGET_ORDER));
  const deleteGroupStatus = useSelector(selectRequestStatus(DELETE_SCHOOL_WIDGET_GROUP));

  const [widgetGroup, setWidgetGroup] = useState<SchoolWidgetDetailsDTO | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [removeWidgetId, setRemoveWidgetId] = useState(0);
  const [formConfig, setFormConfig] = useState({} as FormConfig);
  const [orderChangeable, setOrderChangeable] = useState(false);
  const [selectedWidgets, setSelectedWidgets] = useState<number[]>([]);
  const [newWidgets, setNewWidgets] = useState<number[]>([]);

  const { schoolWidgetGroupId } = useParams<{ schoolWidgetGroupId: string }>();

  const { status } = (widgetGroup as SchoolWidgetDetailsDTO) || {};
  const title = widgetGroup ? `${widgetGroup.schoolName} - ${widgetGroup.scope || 'All Rankings'}` : '';

  const schoolsOption: SelectOptionType[] = useMemo(
    () => schools.map((school: Resource) => ({ label: school.label, value: school.key })),
    [schools],
  );

  const rankingTypesOption: SelectOptionType[] = useMemo(
    () => rankingTypes.map((rankingType: Resource) => ({ label: rankingType.label, value: rankingType.key })),
    [rankingTypes],
  );

  useEffect(() => {
    if (deleteGroupStatus.isSuccess) {
      history.push('/commercial/school-widget-groups');
      dispatch(clearRequestFailedAction(DELETE_SCHOOL_WIDGET_GROUP));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteGroupStatus.isSuccess]);

  useEffect(() => {
    if (orderRequestError && orderChangeable) {
      addToast('Error: Widgets Reordering failed', { appearance: 'error' });
      onTriggerChangeOrder(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderRequestError]);

  useEffect(() => {
    onTriggerChangeOrder(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [widgets]);

  useEffect(() => {
    if (removeWidgetId) {
      setRemoveWidgetId(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newWidgets]);

  useEffect(() => {
    const config: FormConfig = schoolWidgetConfig(schoolsOption, rankingTypesOption);
    setFormConfig(config);
  }, [rankingTypesOption, schoolsOption]);

  useEffect(() => {
    if (updateStatus.isSuccess) {
      dispatch(loadSchoolWidgetGroupAction(+schoolWidgetGroupId));
    }
  }, [schoolWidgetGroupId, updateStatus, dispatch]);

  useEffect(() => {
    dispatch(loadSchoolWidgetsAction(schoolWidgetGroupId));
    dispatch(loadSchoolWidgetGroupAction(+schoolWidgetGroupId));
  }, [schoolWidgetGroupId, dispatch]);

  useEffect(() => {
    dispatch(loadSchoolsDropdownAction());
    dispatch(loadRankingTypesDropdownAction());
  }, [dispatch]);

  useEffect(() => {
    if (rawWidgetGroup) {
      const scope = rawWidgetGroup.rankingType;

      const publicPortalBaseUrl = process.env.REACT_APP_MBA_PUBLIC_PORTAL;

      const previewLink = `<a href="${publicPortalBaseUrl}/widget-group/${rawWidgetGroup.id}" target="_blank">Link</a>`;

      setWidgetGroup({
        id: rawWidgetGroup.id,
        schoolName: rawWidgetGroup.school,
        activeFromDate: (rawWidgetGroup.activeFromDate
          ? new Date(rawWidgetGroup.activeFromDate)
          : new Date()
        ).toISOString(),
        activeToDate: (rawWidgetGroup.activeToDate ? new Date(rawWidgetGroup.activeToDate) : new Date()).toISOString(),
        status: +(rawWidgetGroup.status?.key || 0),
        schoolId: rawWidgetGroup.schoolId,
        widgets: rawWidgetGroup.widgetCount,
        rankingTypeId: rawWidgetGroup.rankingTypeId,
        rankingTypeName: rawWidgetGroup.rankingTypeName,
        previewLink: previewLink,
        scope,
      });
    }
  }, [rawWidgetGroup]);

  const tabsNavigation = Object.values(SCHOOL_WIDGETS_TABS_NAVIGATION(schoolWidgetGroupId)).map(({ label, route }) => ({
    label,
    route: route,
  }));

  const updateDetails = (data: FormDataValues): void => {
    const savingData = {
      activeFromDate: data.activeFromDate ? getParsedDate(data.activeFromDate as string).toISOString() : null,
      activeToDate: data.activeToDate ? getParsedDate(data.activeToDate as string).toISOString() : null,
      rankingTypeId: data.rankingTypeId,
      isActive: +data.status === +CreateSchoolWidgetGroupStatus.Active,
      schoolId: data.schoolId,
    } as UpdateWidgetGroupDto;

    dispatch(updateSchoolWidgetGroupAction(+schoolWidgetGroupId, savingData));
  };

  const onTriggerChangeOrder = (value?: boolean): void => {
    setSelectedWidgets([]);
    setNewWidgets(widgets.map((w) => w.id));
    setOrderChangeable(value === undefined ? !orderChangeable : value);
  };

  const onSaveWidgetsOrder = (): void => {
    if (orderChangeable) {
      const widgetOrders: WidgetOrderDTO[] = newWidgets
        .map((widgetId) => widgets.find((w) => w.id === widgetId))
        .map((widget, index) => ({
          id: widget?.id || 0,
          sortIndex: index,
        }));

      dispatch(updateSchoolWidgetOrderAction(schoolWidgetGroupId, widgetOrders));
    }
  };

  const onMoveUpWidget = (): void => {
    selectedWidgets.forEach((w) => {
      const index = newWidgets.findIndex((nw) => nw === w);
      if (index !== 0 && !selectedWidgets.includes(newWidgets[index - 1])) {
        newWidgets[index] = newWidgets[index - 1];
        newWidgets[index - 1] = w;
      }
    });
    setNewWidgets([...newWidgets]);
  };

  const onMoveDownWidget = (): void => {
    [...selectedWidgets].reverse().forEach((w) => {
      const index = newWidgets.findIndex((nw) => nw === w);
      if (index + 1 !== newWidgets.length && !selectedWidgets.includes(newWidgets[index + 1])) {
        newWidgets[index] = newWidgets[index + 1];
        newWidgets[index + 1] = w;
      }
    });
    setNewWidgets([...newWidgets]);
  };

  const onSelectWidget = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, checked } = event.target;
    if (!checked && selectedWidgets.includes(+name)) {
      setSelectedWidgets(selectedWidgets.filter((widgetId) => widgetId !== +name));
    } else if (checked) {
      const tempWidgets = [...selectedWidgets, +name]
        .map((widgetId) => ({
          id: widgetId,
          index: newWidgets.findIndex((wId) => widgetId === wId),
        }))
        .sort((w1, w2) => w1.index - w2.index)
        .map((w) => w.id);

      setSelectedWidgets(tempWidgets);
    }
  };

  const handleModalClose = (): void => {
    setIsModalOpen(false);
  };

  const onUpdateWidget = (widget: WidgetDTO): void => {
    dispatch(updateSchoolWidgetAction(widget));
  };

  const onDeleteWidget = (id: number): void => {
    dispatch(deleteSchoolWidgetAction(id));
  };

  const openDeleteWidgetGroupModal = (): void => {
    setIsDeleteModalOpen(true);
  };

  const closeDeleteWidgetGroupModal = (): void => {
    setIsDeleteModalOpen(false);
  };

  const openDeleteWidgetModal = (widget: WidgetDTO): void => {
    setRemoveWidgetId(widget.id);
  };

  const closeDeleteWidgetModal = (): void => {
    setRemoveWidgetId(0);
  };

  const onSaveData = (data: CreatableWidget): void => {
    const createdWidget: NewWidgetDTO = {
      type: data.type,
      isHalfWidth: true,
      html: data.html,
      schoolRankingWidgetGroupId: +schoolWidgetGroupId,
      details: data.details,
    };
    dispatch(createSchoolWidgetAction(createdWidget));

    setIsModalOpen(false);
  };

  const handleDeleteClick = (): void => {
    dispatch(deleteSchoolWidgetGroupAction(+schoolWidgetGroupId));
  };

  return (
    <>
      {widgetGroup?.id ? (
        <div className={styles.schoolWidgetDetails}>
          <div className={styles.titleWrapper}>
            <Typography component="h1" variant={TypographyVariants.h1} className={`${styles.pageTitle}`}>
              {title}
            </Typography>
            {!isNaN(status) && (
              <Label color={STATUS_LIST_BY_KEY[status].color} status={LabelStatus.active}>
                {STATUS_LIST_BY_KEY[status].label}
              </Label>
            )}
          </div>
          <TabsNavigation tabs={tabsNavigation} big />
          {widgetGroup && (
            <EditableGroup<SchoolWidgetDetailsDTO>
              columnSize={4}
              data={widgetGroup}
              editButtonLabel="Edit details"
              formConfig={formConfig}
              isLoading={isUpdateWidgetLoading}
              heading={
                <Typography component="p" variant={TypographyVariants.h3} className="mba-heading--title-2">
                  Details
                </Typography>
              }
              deleteButtonBlock={
                <div className="mba-actions--right">
                  <Button
                    primary
                    danger
                    text="Delete group"
                    size={ButtonSizes.big}
                    onClick={openDeleteWidgetGroupModal}
                  />
                </div>
              }
              onEditSubmit={updateDetails}
            />
          )}
          <hr className="mba-separator" />
          <div
            className={`${styles.widgetsTitleWrapper} ${
              orderChangeable ? styles.stickyForOrder : ''
            } mba-heading--wrapper mba-heading--table`}
          >
            <Typography component="h1" variant={TypographyVariants.h1} className={`${styles.widgetsListTitle}`}>
              Widgets
            </Typography>
            <div>
              {orderChangeable && (
                <>
                  <button
                    className={`${styles.btnOrder} ${styles.primary} o-buttons o-buttons--big mba-mr-10`}
                    disabled={selectedWidgets.length === 0}
                    onClick={(): void => onMoveUpWidget()}
                  >
                    <div className={styles.btnContent}>
                      <IconArrowUp width="16" height="16" />
                      <span className={styles.btnLabel}>Move Up</span>
                    </div>
                  </button>
                  <button
                    className={`${styles.btnOrder} ${styles.primary} o-buttons o-buttons--big mba-mr-10`}
                    disabled={selectedWidgets.length === 0}
                    onClick={(): void => onMoveDownWidget()}
                  >
                    <div className={styles.btnContent}>
                      <IconArrowDown width="16" height="16" />
                      <span className={styles.btnLabel}>Move Down</span>
                    </div>
                  </button>
                  <Button
                    text="Save"
                    size={ButtonSizes.big}
                    wrapperClass="mba-mr-10"
                    primary
                    icon={ButtonIcons.tick}
                    isLoading={isUpdateWidgetOrderLoading}
                    onClick={(): void => onSaveWidgetsOrder()}
                  />
                </>
              )}
              <button
                className={`${styles.btnOrder} ${
                  styles[orderChangeable ? 'danger' : 'primary']
                } o-buttons o-buttons--big mba-mr-10`}
                onClick={(): void => onTriggerChangeOrder()}
              >
                <div className={styles.btnContent}>
                  {orderChangeable ? <IconDelete width="16" height="16" /> : <IconEdit width="16" height="16" />}
                  <span className={styles.btnLabel}>{orderChangeable ? 'Cancel' : 'Change orders'}</span>
                </div>
              </button>
            </div>
            <Button
              text="Add widget to group"
              size={ButtonSizes.big}
              primary
              icon={ButtonIcons.plus}
              onClick={(): void => setIsModalOpen(true)}
            />
          </div>
          {newWidgets
            .map((widgetId) => widgets.find((w) => w.id === widgetId))
            .map((item = {} as WidgetDTO) => (
              <div key={`${item.id}-${item.sortIndex}`} className="mba-mb-20">
                <EditableWidget
                  widget={item}
                  orderChangeable={orderChangeable}
                  selectedForOrder={selectedWidgets.includes(item.id)}
                  onEdit={onUpdateWidget}
                  onDelete={openDeleteWidgetModal}
                  onSelectForOrder={onSelectWidget}
                />
              </div>
            ))}
          {isModalOpen && (
            <CreateWidgetModal
              onSave={onSaveData}
              isLoading={isCreateLoading}
              isModalOpen={isModalOpen}
              handleModalClose={handleModalClose}
              widgets={widgets}
            />
          )}
          {isDeleteModalOpen && (
            <Modal title="Remove Widget Group" isOpen={isDeleteModalOpen}>
              <RemoveWidgetGroupPopup onConfirm={handleDeleteClick} onCancel={closeDeleteWidgetGroupModal} />
            </Modal>
          )}
          {!!removeWidgetId && (
            <Modal title="Remove Widget" isOpen={!!removeWidgetId}>
              <RemoveWidgetPopup
                widgetId={removeWidgetId}
                onConfirm={onDeleteWidget}
                onCancel={closeDeleteWidgetModal}
              />
            </Modal>
          )}
        </div>
      ) : isLoading || !schoolWidgetGroupId ? (
        <LoadingSpinner />
      ) : (
        <NotFound />
      )}
    </>
  );
};

export default SchoolWidgetDetails;
